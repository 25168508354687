import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

// redirectTo: '/store' みたいにすると、 /store に遷移するときに、 /store?query=xxx みたいなクエリパラメータがついてこない
// それを解決するための guard
// https://github.com/angular/angular/issues/28661
export function redirectWithQueryParams(redirectTo: string): CanActivateFn {
  return (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): UrlTree => {
    const router = inject(Router);
    const queryParams = route.queryParams;

    return router.createUrlTree([redirectTo], { queryParams, queryParamsHandling: 'merge' });
  };
}
